<template>
  <div>
    <v-card>
      <v-card-title>Administrar Poderes</v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="stateEntities"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Editar texto"
              @click="editEntity(item.id)"
              icon
            >
              <v-icon>mdi-script-text-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {},
  data: () => ({
    loading: false,
    headers: [
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    stateEntities: [],
  }),
  methods: {
    async loadStateEntities() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/poderes`);

        this.setEntities(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setEntities(data) {
      const cpData = [...data];

      this.stateEntities = cpData.map((e) => ({
        ...e,
      }));
    },
    editEntity(id) {
      this.$router.push(`/admin/poderes/${id}/editar`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadStateEntities();
  },
};
</script>

<style></style>
